<script setup lang="ts">
import type { UtilsSnackbarGroupWrapper } from './UtilsSnackbarGroupWrapper.props'
withDefaults(defineProps<UtilsSnackbarGroupWrapper>(), {
  tag: 'div',
})

const runFn = (fn: Function, delay: number = 300) =>
  setTimeout(() => {
    fn()
  }, delay)
</script>
<template>
  <TransitionGroup
    :tag="tag"
    name="on-show"
    class="utils-snackbar-group-wrapper"
    @leave="
      (el, done) => {
        const element = el as HTMLElement
        element.style.height = '0px'
        runFn(done)
      }
    "
  >
    <slot />
  </TransitionGroup>
</template>

<style scoped lang="scss">
.utils-snackbar-group-wrapper {
  :deep(.utils-snackbar) {
    transition: all 0.3s ease-in-out;
    display: inline-block;
  }

  :deep(.on-show-enter-from) {
    --tw-translate-y: -1rem;
    opacity: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y));
  }

  :deep(.on-show-leave-to) {
    --tw-translate-y: -1rem;
    opacity: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y));
  }
}
</style>
